<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <KnowledgeFilter
      :search.sync="filter.searchCodeAndSubject"
      :status-filter.sync="filter.status"
      @fetch-filter="fetchFilter($event)" />
    <b-card class="mt-2">
      <TableTop
        v-if="$checkRole(addKnowledgePermission, profileData.role)"
        v-model="pagination.limit"
        label="Add Knowledge Base"
        to-path="/knowledge/add"
        @input="fetchFilter()" />
      <b-table
        striped
        hover
        responsive
        :items="items"
        :fields="fields"
        @row-clicked="rowClick($event)"
      >
        <template #cell(knowledgeType)="{ item }">
          <div
            v-if="item && item.knowledgeType"
            class="font-weight-semibold">
            {{
              item.knowledgeType.title
            }}
          </div>
        </template>
        <template #cell(code)="data">
          <span class="font-weight-semibold text-primary">#{{ data.value }}</span>
        </template>
        <template #cell(subject)="data">
          <span class="font-weight-semibold">{{ data.value }}</span>
        </template>
        <template #cell(problemDetail)="data">
          <span
            class="font-weight-semibold wrap-text"
            v-html="$wrapWords(data.value, 10)">
          </span>
        </template>
        <template #cell(solutionDetail)="data">
          <span
            class="font-weight-semibold wrap-text"
            v-html="$wrapWords(data.value, 10)"></span>
        </template>
      </b-table>
      <b-row>
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination
            v-model="pagination.page"
            :total-rows="pagination.totalDocs"
            :per-page="pagination.limit"
            first-number
            last-number
            class="mb-1 mt-2 mr-1"
            prev-class="prev-item"
            next-class="next-item"
            @change="showCurrentPage($event)">
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18" />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import TableTop from '@/components/TableTop.vue'
import KnowledgeBaseProvider from '@/resources/KnowledgeBaseProvider'
import KnowledgeFilter from '@/views/Knowledges/components/KnowledgeFilter.vue'
import AddKnowledgePermission from '@/common/permission/knowledge/add-knowledge'

const KnowledgeBaseService = new KnowledgeBaseProvider()

export default {
  inject: ['profileData'],
  components: {
    TableTop,
    KnowledgeFilter
  },
  data () {
    return {
      filter: {
        searchCodeAndSubject: ''
      },
      pagination: {
        page: 1,
        limit: 10,
        totalDocs: 10
      },
      fields: [
        { key: 'code', label: 'Code', sortable: true },
        {
          key: 'subject',
          label: 'Subject',
          thStyle: {
            width: '20rem'
          }
        },
        { key: 'problemDetail', label: 'Problem' },
        { key: 'solutionDetail', label: 'Solution' },
        { key: 'knowledgeType', label: 'Type' }
        // { key: 'updatedAt', label: 'Updated At', sortable: true },
        // { key: 'updatedBy', label: 'Updated By' },
      ],
      items: [
        {
          Code: 'test01',
          Name: 'test01',
          Problem: 'test01',
          Solution: 'test01',
          status: 'Urgent'
        },
        {
          Code: 'test02',
          Name: 'test02',
          Problem: 'test02',
          Solution: 'test02',
          status: 'Not Urgent'
        },
        {
          Code: 'test03',
          Name: 'test03',
          Problem: 'test03',
          Solution: 'test03',
          status: 'Very Urgent'
        },
        {
          Code: 'test04',
          Name: 'test04',
          Problem: 'test04',
          Solution: 'test04',
          status: 'Not Urgent'
        }
      ],
      addKnowledgePermission: AddKnowledgePermission
    }
  },
  created () {
    this.getKnowledge()
  },
  methods: {
    async getKnowledge () {
      try {
        this.$store.dispatch('activity/increaseLoading')
        const data = await KnowledgeBaseService.paginate(this.pagination.page, this.pagination.limit, this.filter)
        this.setPagination(data.page, data.totalDocs)
        this.items = [...data.data]
      } catch (err) {
        console.error(err)
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    fetchFilter (data) {
      if (data) {
        this.filter = data
      }
      this.setPagination(1)
      this.getKnowledge()
    },
    rowClick (val) {
      this.$router.push(`/knowledge/detail/${val.id}`)
    },
    showCurrentPage (page) {
      this.setPagination(page)
      this.getKnowledge()
    },
    setPagination (page, totalDocs) {
      this.pagination.page = page || this.pagination.page
      this.pagination.totalDocs = totalDocs === 0 ? 0 : totalDocs || this.pagination.totalDocs
    }
  }
}
</script>

<style lang="scss" scoped>
.v-select {
  background: white;
}
.card-body {
  padding: 0;
}
::v-deep input.vs__search::placeholder {
  color: #B9B9C3;
}

.wrap-text {
   overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
   -webkit-box-orient: vertical;
}
</style>
